import { post } from '@/utils/request';

// 获取打卡列表
const getListReq = (params) => post({
  url: '/store/system.Feedback/list',
  data: {
    ...params,
  },
});

// 添加备注
const remarkReq = ({ feedback_id, remark, }) => post({
  url: '/store/system.Feedback/remark',
  data: {
    feedback_id,
    remark,
  },
});

export {
  getListReq,
  remarkReq,
};
