<template>
  <div>
    <div class="card" style="display: flex">
      <div class="middle" style="padding-left: 10px;">
        <el-form :model="form" :inline="true">
          <el-form-item label="反馈内容">
            <el-input
              placeholder="请输入反馈内容"
              v-model="form.reason"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="姓名">
            <el-input
              placeholder="请输入姓名"
              v-model="form.user_name"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="手机号">
            <el-input
              placeholder="请输入手机号"
              v-model="form.user_phone"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="反馈时间">
            <el-date-picker
              v-model="form.create_time"
              type="daterange"
              value-format="yyyy-MM-dd"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="onSubmit">搜索</el-button>
          </el-form-item>
           <el-form-item>
              <el-button  @click="resetForm">重置</el-button>
            </el-form-item>
        </el-form>
      </div>
    </div>
    <div class="table">
      <el-table
        :data="tableData.list"
        style="width: 100%"
        v-loading="loading"
        height="700"
        stripe
      >
        <template v-for="item in rowList">
          <el-table-column
            :key="item.field_alias"
            :prop="item.field_alias"
            :label="item.field_text"
            align="center"
            :width="item.t_width"
          >
            <template slot-scope="scope">
              <template v-if="item.field_alias !== 'pic'">
                {{
                  scope.row[item.field_alias] ? scope.row[item.field_alias] : "--"
                }}
              </template>
              <template v-else>
                 <el-image
                  fit="cover"
                  style="width: 46px; height: 46px; margin-top: 4px;"
                  :src="scope.row.pic? scope.row.pic[0]: ''"
                  :preview-src-list="scope.row.pic">
                  <div slot="error" class="image-slot">
                    <img src="../../../../assets/images/no_img.png" style="width: 46px; height: 46px; object-fit: cover; margin-top: 4px;" />
                  </div>
                </el-image>
              </template>
            </template>
          </el-table-column>
        </template>
        <el-table-column label="操作" align="center">
            <template slot-scope="scope">
              <el-button type="text" @click="handleMark(scope.row)">备注</el-button>
            </template>
          </el-table-column>
      </el-table>
    </div>
    <div class="pagina">
      <el-pagination
        background
        :current-page="page"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :page-sizes="[15, 30, 40, 50]"
        :page-size="15"
        layout="total, sizes, prev, pager, next, jumper"
        :total="tableData.total"
      >
      </el-pagination>
    </div>
    <el-dialog
      title="添加备注"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose">
      <el-input
        type="textarea"
        :rows="5"
        placeholder="请输入备注"
        maxlength="200"
        show-word-limit
        v-model="remark">
      </el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="handleTrue">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import moment from 'moment';
import { getListReq, remarkReq } from "@/api/system/suggestion";

export default {
  data() {
    return {
      currRow: {},
      remark: '',
      dialogVisible: false,
      page: 1,
      limit: 15,
      loading: false,
      merchantList: [], // 门店列表
      label: '',
      form: {},
      rowList: [
        { field_text: "序号", field_alias: "index" },
        { field_text: "反馈内容", field_alias: "reason" },
        { field_text: "图片", field_alias: "pic" },
        { field_text: "姓名", field_alias: "user_name" },
        { field_text: "手机号", field_alias: "user_phone" },
        { field_text: "反馈时间", field_alias: "create_time" },
        { field_text: "备注", field_alias: "remark" },
      ],
      tableData: {
        list: [{}],
        total: 0,
      },
    };
  },
  components: {
  },
  created() {
    // 获取门店列表
    this.getList();
  },
  methods: {
    resetForm() {
      this.form = {};
      this.page = 1;
      // this.limit = 15;
      this.getList();
    },
    handleTrue() {
      remarkReq({
        feedback_id: this.currRow.feedback_id,
        remark: this.remark,
      })
        .then((res) => {
          if (res.code === 1) {
            this.$message.success('操作成功');
            this.handleClose();
            this.getList();
          }
        });
    },
    handleClose() {
      this.dialogVisible = false;
      this.remark = '';
    },
    // 备注
    handleMark(row) {
      this.dialogVisible = true;
      this.currRow = row;
    },
    // 获取打卡列表
    getList() {
      this.loading = true;
      getListReq({
        ...this.form,
        page: this.page,
        limit: this.limit,
      })
        .then((res) => {
          if (res.code === 1) {
            this.loading = false;
            res.data.list.forEach((item, index) => {
              item.index = index + 1;
              item.create_time = moment(item.create_time * 1000).format('YYYY-MM-DD HH:mm:ss');
            });
            this.tableData = res.data;
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    onSubmit() {
      this.page = 1;
      this.getList();
    },
    // 选择部门子组件回调事件
    handleSelect(nodeList) {
      console.log(nodeList);
      if (nodeList.length === 1) {
        this.label = nodeList[0].label;
      } else {
        this.label = `${nodeList[0].label}等多个部门`;
      }
      this.form.select = 1;
    },
    handleHideDepartment() {
      this.dialogVisible = false;
    },
    // 分页页数改变
    handleCurrentChange(page) {
      this.page = page;
      this.getList();
    },
    // 分页条数改变
    handleSizeChange(limit) {
      this.page = 1;
      this.limit = limit;
      this.getList();
    },
    // 导出
    handleExport() {},
    // 选择部门
    handleChoose() {
      this.dialogVisible = true;
    },
  },
};
</script>

<style lang="less" scoped>
.middle {
  /deep/ .el-form-item {
    margin-bottom: 0px;
  }
}
</style>
